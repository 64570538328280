<template>
    <v-card>
        <v-card-title>{{this.chartProp.title}}</v-card-title>
        <v-card-text v-if="this.chartProp.description">{{this.chartProp.description}}<br>
        <span v-for="(filter,index) in chartProp.filterList" :key="'filter' + index">{{filter.alias}}{{filter.value}}<br></span>
        </v-card-text>

        <div id="pchart">
            <apexchart type="pie" height="350" :options="chartOptions" :series="series"></apexchart>
            </div>					
        <v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    name: "PieChart",
    props: {
        chartProp:Object
    },      
    components:{
        apexchart: VueApexCharts,
    },
    computed: {
    },
        data() {
            return{
                series: [44, 55, 13, 43, 22],
                chartOptions: {

                    chart: {
                        width: 380,
                        type: 'pie',
                    },
                    labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }],
                    theme: {
                        monochrome: {
                            enabled: false
                        }
                    },
                    plotOptions: {
                        pie: {
                            dataLabels: {
                            offset: -5
                            }
                        }
                    },
                    title: {
                        text: ""
                    },
                    dataLabels: {
                        formatter(val,opts) {
                            // const name = opts.w.globals.labels[opts.seriesIndex]
                            // return [name, val.toFixed(1) + '%']
                            const percentage = val.toFixed(1) + '%'
                            const value = "value:" + opts.w.config.series[opts.seriesIndex]
                            return [percentage,value]
                        }
                    },
                },
            }
        },
        created(){
            
            this.series = this.chartProp.series;
            this.chartOptions.labels = this.chartProp.labels;
            this.chartOptions.colors = this.chartProp.colors;
        },
}    

</script>