<template>
    <v-card>
        <v-card-title>{{this.chartProp.title}}</v-card-title>
        <v-card-text v-if="this.chartProp.description">{{this.chartProp.description}}<br>
        <span v-for="(filter,index) in chartProp.filterList" :key="'filter' + index">{{filter.alias}}{{filter.value}}<br></span>
        </v-card-text>
        <div id="chart">
            <apexchart ref="refChart" type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
            </div>					
        <v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    name: "BarChart",
    props: {
        chartProp:Object
    },      
    components:{
        apexchart: VueApexCharts,
    },
        data() {
            return{
                series: [{
                            name: 'data',
                            data: [0],//change to variable
                        }],
                chartOptions: {
                    chart: {height: 350,type: 'bar',},
                    fill: {colors: ['#F44336', '#E91E63', '#9C27B0']},//change to variable
                    plotOptions: {
                        bar: {
                            //borderRadius: 10, 
                            dataLabels: {
                                position: 'top', // top, center, bottom                       
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        // formatter: function (val) {
                        //     return val + "%";
                        // },
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"], //change to variable
                        }
                    },
                    
                    xaxis: {
                        title:{
                            text:"",
                        },
                        categories: ["0"],//change to variable
                        position: 'bottom',
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        crosshairs: {
                            fill: {
                                type: 'gradient',
                                gradient: {
                                    colorFrom: '#E91E63',
                                    colorTo: '#BED1E6',
                                    stops: [0, 100],
                                    opacityFrom: 0.4,
                                    opacityTo: 0.5,
                                }
                            }
                        },
                        tooltip: {
                            enabled: true,
                        }
                    },
                    yaxis: {
                        title: {
                            text: ''
                        },                        
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },                        
                        labels: {
                        show: true,
                            // formatter: function (val) {
                            // return val + "%";
                            // }
                        }                    
                    },
                    title: {
                        text: '', //change to variable
                        floating: true,
                        offsetY: 0,
                        align: 'center',
                        style: {
                            color: '#444'
                        }
                    }
                },
            }
        },
        created(){
            this.renderChart()            ;
        },
        
  
    methods:{
        renderChart(){
            this.chartOptions.title.text = this.chartProp.title;
            this.series = this.chartProp.series;
            this.chartOptions.xaxis.categories = this.chartProp.xcategories;
            this.chartOptions.yaxis.title.text = this.chartProp.yaxisTitle;
            this.chartOptions.xaxis.title.text = this.chartProp.xaxisTitle;
            this.chartOptions.fill.colors = this.chartProp.barColors;

        }
    },
    computed:{
    },

    watch:{
    }
        
}    

</script>