<template>
    <v-card>
        <v-card-title>
            <DlgExportExcel ref="dlgExportExcel" :tblHeaders="chartProp.tblHeaders" :tblBody="chartProp.tblBody" filename="report" bookType="xlsx"></DlgExportExcel>
        </v-card-title>

        <v-card-title>{{this.chartProp.title}}</v-card-title>
        <v-card-text>{{this.chartProp.description}}<br>
            <span v-for="(filter,index) in chartProp.filterList" :key="'filter' + index">{{filter.alias}}{{filter.value}}<br></span>
        </v-card-text>
        
        <v-data-table
            ref="vuetable"
            :headers="this.chartProp.tblHeaders"
            :items="this.chartProp.tblBody"
        ></v-data-table>
        <v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
import DlgExportExcel from '@/components/DlgExportExcel'
export default {
    
    name: "TableChart",
    props: {
        chartProp:Object
    },      
    components:{DlgExportExcel},
    computed: {
    },
        data() {
            return{
            }
        },
        created(){
           
        },
}    

</script>