<template>
    <v-card>
        <v-card-title>{{this.chartProp.title}}</v-card-title>
        <v-card-text v-if="this.chartProp.description">{{this.chartProp.description}}<br>
        <span v-for="(filter,index) in chartProp.filterList" :key="'filter' + index">{{filter.alias}}{{filter.value}}<br></span>
        </v-card-text>

        <div id="lchart">
            <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
            </div>					
        <v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    name: "LineChart",
    props: {
        chartProp:Object
    },      
    components:{
        apexchart: VueApexCharts,
    },
    computed: {
    },
        data() {
            return{
                series: [{
                    name: "Desktops",
                    data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
                }],
            
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    fill: {colors: ['#F44336', '#E91E63', '#9C27B0']},//change to variable
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Product Trends by Month',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                        title:{
                            text:"",
                        },
                        categories: ["0"],//change to variable
                        position: 'bottom',
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        crosshairs: {
                            fill: {
                                type: 'gradient',
                                gradient: {
                                    colorFrom: '#E91E63',
                                    colorTo: '#BED1E6',
                                    stops: [0, 100],
                                    opacityFrom: 0.4,
                                    opacityTo: 0.5,
                                }
                            }
                        },
                        tooltip: {
                            enabled: true,
                        }
                    },
                    yaxis: {
                        title: {
                            text: ''
                        },                        
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },                        
                        labels: {
                        show: true,
                            // formatter: function (val) {
                            // return val + "%";
                            // }
                        }                    
                    },
            },
            }
        },
        created(){
            
            this.chartOptions.title.text = this.chartProp.title;
            this.series = this.chartProp.series;
            this.chartOptions.xaxis.categories = this.chartProp.xcategories;
            this.chartOptions.yaxis.title.text = this.chartProp.yaxisTitle;
            this.chartOptions.xaxis.title.text = this.chartProp.xaxisTitle;
            this.chartOptions.fill.colors = this.chartProp.barColors;
        },
}    

</script>